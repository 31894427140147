import {
  AnswerApplicationQuestionRequest,
  ApplicationStep,
  FieldObject,
  FieldObjectTypeEnum,
  Question,
  SectionProgressDto,
} from '../openapi/atlantis';
import {
  formatAddress,
  getAddressFromAddressComponent,
} from './GoogleMapUtils';
import { getBooleanParse } from './TypeUtils';

export enum NextActionType {
  INITIATE = 'INITIATE',
  BUTTON_ACTION = 'BUTTON_ACTION',
}

export enum DisplayTypeInputEnum {
  SINGLE_SELECT_IMAGE = 'SINGLE_SELECT_IMAGE',
  SINGLE_SELECT_DROP_DOWN = 'SINGLE_SELECT_DROP_DOWN',
  SINGLE_SELECT_IMAGE_DESCRIPTION = 'SINGLE_SELECT_IMAGE_DESCRIPTION',
  CONSENT_CHECKBOX = 'CONSENT_CHECKBOX',
}

export interface ApplicationStepWithNewContent<T>
  extends Omit<ApplicationStep, 'content'> {
  id?: string | undefined;
  content: T;
}

export const isEmptyAnswer = (answer: any): boolean => {
  if (answer === null || answer === undefined || answer === '') {
    return true;
  }

  return false;
};

// this function returns the answer for a address field
export const getAddressFieldAnswer = (answer: Record<string, any>) => {
  if (!answer) {
    return;
  }

  if (answer?.isManualAddress) {
    return {
      type: answer?.type || null,
      country: 'UNITED_STATES',
      streetAddress1: answer?.streetAddress1 || null,
      streetAddress2: answer?.streetAddress2 || null,
      city: answer?.city || null,
      county: answer?.county || null,
      stateOrProvince: answer?.stateOrProvince,
      zipOrPostalCode: answer?.zipOrPostalCode,
    };
  }

  if (answer?.place_id) {
    return getAddressFromAddressComponent(answer?.address_components, [
      'streetAddress2',
    ]);
  }

  return answer;
};

//this function returns the answer for a field based on its type
export const getFieldAnswer = (field: FieldObject, answer: any) => {
  if (isEmptyAnswer(answer)) {
    if (answer === '') {
      return null;
    }

    return answer;
  }

  switch (field.type) {
    case FieldObjectTypeEnum.Address:
      return getAddressFieldAnswer(answer);

    case FieldObjectTypeEnum.FileRequest:
    case FieldObjectTypeEnum.Binary:
    case FieldObjectTypeEnum.Toggle:
      return getBooleanParse(answer);

    case FieldObjectTypeEnum.Number:
      return Number(answer);

    case FieldObjectTypeEnum.Money:
      return { amount: answer || 0, currency: 'USD' };

    default:
      return answer;
  }
};

// this function returns the answer for a field array
export const getFieldArrayAnswer = (field: FieldObject, fieldAnswer: any[]) => {
  const fieldArrayAnswer = (fieldAnswer || []).map((fieldValue: any) => {
    //@ts-ignore
    return (field.fields || []).map((childField: FieldObject) => {
      const value = fieldValue[childField.id!];

      return {
        fieldId: childField.id,
        answerType: childField.answerType,
        answer: getFieldAnswer(childField, value),
      };
    });
  });

  const answer = {
    ...field,
    answer: fieldArrayAnswer,
  };

  return [answer];
};

// this function returns the answers for fields in a form.
export const getFieldsAnswers = (
  fields: FieldObject[],
  formData: Record<string, any>,
) => {
  return (fields || []).flatMap((field: FieldObject) => {
    if (!formData.hasOwnProperty(field.id!)) {
      return [];
    }

    const fieldAnswer = formData[field.id!];

    if (field.type === FieldObjectTypeEnum.FieldArray) {
      return getFieldArrayAnswer(field, fieldAnswer);
    }

    const answer = {
      ...field,
      answer: getFieldAnswer(field, fieldAnswer),
    };

    return [answer];
  });
};

//this function returns the answer for a question form
export const getFormQuestionAnswers = (
  config: ApplicationStepWithNewContent<Question>,
  formData: Record<string, any>,
): AnswerApplicationQuestionRequest => {
  const fields = [
    ...(config.content.fields || []),
    ...((config.content.segments || []).flatMap((s) => s.fields) || []),
  ];

  const processedFields = getFieldsAnswers(fields, formData);

  const answers = (processedFields || []).map((field) => ({
    fieldId: field.id,
    answer: field.answer,
    answerType: field.answerType,
  }));

  return {
    questionId: config.content.id!,
    //@ts-ignore
    answers,
  };
};

// This function returns the default values for an address field.
export const getAddressFieldDefaultValue = (
  address: any,
): Record<string, any> | undefined => {
  if (!address) {
    return;
  }

  return {
    formatted_address: formatAddress(address),
    country: address?.country,
    streetAddress1: address?.streetAddress1,
    streetAddress2: address?.streetAddress2,
    city: address?.city,
    county: address?.county,
    stateOrProvince: address?.stateOrProvince,
    zipOrPostalCode: address?.zipOrPostalCode,
  };
};

// This function returns the default values for a specified field based on its type.
export const getFieldDefaultValues = (field: FieldObject, answer: any): any => {
  switch (field.type) {
    case FieldObjectTypeEnum.Address:
      return getAddressFieldDefaultValue(answer);

    case FieldObjectTypeEnum.Number:
      return answer?.toString();

    case FieldObjectTypeEnum.Money:
      return answer?.amount?.toString();

    case FieldObjectTypeEnum.Toggle:
      return answer?.toString() ?? field?.presetAnswer?.toString();

    case FieldObjectTypeEnum.Binary:
      return answer?.toString();

    case FieldObjectTypeEnum.FileRequest:
      return answer?.toString();

    case FieldObjectTypeEnum.UrlRequest:
      return true;

    default:
      return answer;
  }
};

// This function returns an default values for a field array.
export const getFieldArrayDefaultValues = (
  fields: FieldObject[],
  fieldAnswer: any[],
): Record<string, any> => {
  const fieldArrayDefaultValues: Record<string, any> = {};

  (fieldAnswer || []).forEach(({ fieldId, answer }) => {
    const field = (fields || []).find((f) => f.id === fieldId);
    if (field) {
      const defaultValue = getFieldDefaultValues(field, answer);
      fieldArrayDefaultValues[fieldId] = defaultValue;
    }
  });

  return fieldArrayDefaultValues;
};

// This function sets the default values for fields in a form or segment.
export const getFieldsDefaultValues = (
  fields: FieldObject[] = [],
  answered: boolean,
): Record<string, any> => {
  const fieldsDefaultValues: Record<string, any> = {};

  (fields || []).forEach((field) => {
    const { id, type, answer, presetAnswer } = field;
    const fieldAnswer = answered ? answer : presetAnswer;

    switch (type) {
      case FieldObjectTypeEnum.FieldArray:
        const fieldArrayAnswers = fieldAnswer?.map((ans: any) => {
          //@ts-ignore
          return getFieldArrayDefaultValues(field.fields, ans);
        });
        fieldsDefaultValues[id!] = fieldArrayAnswers;
        break;
      default:
        const defaultAnswer = getFieldDefaultValues(field, fieldAnswer);
        fieldsDefaultValues[id!] = defaultAnswer;
        break;
    }
  });

  return fieldsDefaultValues;
};

// This function returns the default values for a form, given its configuration.
export const getFormDefaultValues = (
  config: ApplicationStepWithNewContent<Question>,
): Record<string, any> => {
  const formDefaultValues = Object.assign(
    {},
    getFieldsDefaultValues(config.content.fields, config.content.answered!),
    ...(config.content.segments || []).map((segment) =>
      getFieldsDefaultValues(segment.fields, config.content.answered!),
    ),
  );

  return formDefaultValues;
};

// this function returns the id of the previous question in the application
export const getPreviousQuestionId = (
  sectionProgresses: Array<SectionProgressDto>,
  questionId?: string,
) => {
  const allQuestions = (sectionProgresses || []).flatMap(
    (section) => section.questionProgresses,
  );

  const currentQuestionIndex = (allQuestions || []).findIndex(
    (question) => question.id === questionId,
  );

  // No previous question found or the current question is the first one
  if (currentQuestionIndex < 1) {
    return null;
  }

  const previousQuestion = allQuestions[currentQuestionIndex - 1];

  return previousQuestion.id;
};

// this function returns the id of the previous question in the application
export const getOnboardingPreviousQuestionId = (
  sectionProgresses: Array<SectionProgressDto>,
  questionId?: string,
) => {
  const currentSection = sectionProgresses.find(
    (section) =>
      section.status === 'NOT_STARTED' || section.status === 'IN_PROGRESS',
  );

  const allQuestions = currentSection?.questionProgresses || [];

  const currentQuestionIndex = allQuestions.findIndex(
    (question) => question.id === questionId,
  );

  // No previous question found or the current question is the first one
  if (currentQuestionIndex < 1) {
    return null;
  }

  const previousQuestion = allQuestions[currentQuestionIndex - 1];

  return previousQuestion.id;
};
